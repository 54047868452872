.EventPlot {
  /*background: #fdfbfb;*/
  margin: 20px 0 60px;
}
.EventPlot .stageLabels {
  height: 30px;
}
.EventPlot .stageLabel {
  position: absolute;
  font-size: 14px;
  color: #666;
  font-weight: 600;
  border-left: 1px solid #bbb;
  padding-left: 6px;
  padding-top: 4px;
}

.EventPlot .percentageContainer {
  width: calc(100% - 70px);
  position: relative;
  float: left;
  overflow-x: scroll;
  border-radius: 3px;
}
.EventPlot .percentageContainerSlider {
  margin-left: 2px;
  position: relative;
}

.EventPlot .leftLabels {
  width: 60px;
  margin-top: 30px;
  float: left;
  text-align: right;
}

.EventPlot .leftLabel {
  font-size: 15px;
  color: #666;
  font-weight: 600;
  height: 23px;
  box-sizing: border-box;
  padding: 3px 5px 0 0;
}

.EventPlot .dot {
  height: 6px;
  position: absolute;
  border-left: 1px solid #fff;
  box-sizing: border-box;
}

.EventPlot .dotRow {
  height: 23px;
  box-sizing: border-box;
  padding-top: 9px;
}

.EventPlot .dateLabels {
  height: 30px;
}

.EventPlot .dateLabel {
  position: absolute;
  font-size: 14px;
  color: #666;
  font-weight: 600;
  transform-origin: top left;
  margin-top: 8px;
  white-space: nowrap;
  border-left: 1px solid #bbb;
  height: 20px;
  padding: 3px 0 0 5px;
}

.EventPlot .progress {
  background: #fff;
  height: 6px;
  position: absolute;
  box-shadow: 0 0 2px rgba(0,0,0,0.15);
}

.EventPlot .todayDot {
  position: absolute;
  height: 6px;
  background: #007dcc;
  border-left: 1px solid #007dcc;
  box-sizing: border-box;
}

.EventPlot ::-webkit-scrollbar {
  height: 8px;
  width: 5px;
  background: #eee;
}

.EventPlot ::-webkit-scrollbar-track {
  background: none;
}

.EventPlot ::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 0;
}

.EventPlot ::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
