.Footer .footerLogo {
  display: inline-block;
  background: url('./footerBgStart.png') left top/cover no-repeat;
  vertical-align: top;
  height: 100px;
  width: 230px;
}
.Footer .footerImg {
  width: 80px;
  margin: 10px 0 0 40px;
}

.Footer .footerText {
  display: inline-block;
  background: #149dac;
  color: #fff;
  font-size: 16px;
  box-sizing: border-box;
  vertical-align: top;
  height: 100px;
  padding-left: 10%;
  padding-top: 35px;
  width: calc(100% - 340px);
}
@media (max-width: 620px) {
  .Footer .footerText {
    padding-top: 25px;
  }
}
@media (max-width: 500px) {
  .Footer .footerText {
    padding-top: 30px;
    padding-right: 5px;
    padding-left: 10px;
    width: calc(100% - 230px);
  }
}
@media (max-width: 365px) {
  .Footer .footerText {
    padding-left: 0;
    padding-top: 20px;
  }
}
@media (max-width: 340px) {
  .Footer .footerText {
    padding-left: 0;
    padding-top: 20px;
  }
}

.Footer .footerText p {
  margin: 0 0 10px 0;
}

.Footer .footerLink {
  color: #fff;
}

.Footer .footerEnd {
  display: inline-block;
  background: url('./footerBgEnd.png') left top/contain no-repeat;
  vertical-align: top;
  height: 100px;
  width: 110px;
}
@media (max-width: 500px) {
  .Footer .footerEnd {
    display: none;
  }
}


