.DataRetain {
  background: #fdf5f4;
  min-height: calc(100vh - 120px);
  position: relative;
  padding-top: 100px;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 18px;
}

.DataRetain .portraitCont {
  float: left;
  width: 120px;
}
.DataRetain .portrait {
  width: 120px;
}
.DataRetain .portraitImg {
  display: block;
  width: 100%;
}
.DataRetain .caption {
  color: #666;
  font-size: 13px;
  margin-top: 10px;
}

.DataRetain .page {
  padding: 20px 30px 40px;
}
.DataRetain .quote {
  float: left;
  width: calc(100% - 160px);;
  padding: 0 0 0 40px;
  color: #555;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  min-height: 120px;
  display: flex;
  align-items: center;
}
@media (max-width: 450px) {
  .DataRetain .page {
    background: #fff;
    padding: 20px 10px 30px;
  }
  .DataRetain .portraitCont {
    margin-left: 0;
    width: 40%;
  }
  .DataRetain .portrait {
    width: 80px;
    height: 80px;
  }
  .DataRetain .quote {
    vertical-align: top; 
    font-size: 18px;
    padding: 10px 0 0 10px;
  }
}

.DataRetain .buttons {
  margin: 20px 0 70px;
}

.DataRetain .back {
  cursor: pointer;
  color: #007dcc;
  border: 1px solid rgba(0,0,0,0);
  display: inline-block;
  padding: 12px 10px 5px 5px;
  border-radius: 15px;
  float: left;
  margin: 8px 0px 20px;
  font-size: 14px;
  text-decoration: underline;
}
.DataRetain .back:before {
  content: " ";
  display: inline-block;
  vertical-align: -3px;
  color: #007dcc;
  width: 20px;
  height: 18px;
  background: url('./chevron.svg') center/contain no-repeat;
  transform: rotate(180deg);
}

.DataRetain .next {
  cursor: pointer;
  color: #007dcc;
  font-weight: 600;
  border: 1px solid;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  float: right;
  margin: 10px 20px 0 0;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
  background: #fff;
  opacity: 0.4;
  pointer-events: none;
}
.DataRetain .next.active {
  opacity: 1;
  pointer-events: all;
}
.DataRetain .next:after {
  content: " ";
  display: inline-block;
  vertical-align: -3px;
  width: 20px;
  height: 18px;
  margin-left: 0;
  background: url('./chevron.svg') center/contain no-repeat;
}


.DataRetain .option {
  margin: 10px 0;
  border-radius: 10px;
  background: #fff;
  padding: 15px;
  cursor: pointer;
  position: relative;
  padding-left: 45px;
}

.DataRetain .option .cb {
  border-radius: 3px;
  background: #fff;
  border: 1px solid #000;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
}

.DataRetain .option.active .cb {
  background: #4abdca;
  border: 1px solid #4abdca;
}

.DataRetain .option_set {
  margin: 25px;
  padding-top: 30px;
  clear: both;
}


.DataRetain .msg {
  margin: 25px;
  padding-top: 30px;
  clear: both;
}
.DataRetain .title {
  font-size: 19px;
  color: #333;
  font-weight: 600;
}
.DataRetain .instruction {
  font-size: 17px;
  line-height: 20px;
  padding: 5px 0 5px;
  color: #444;
}

