.SignUp {
  padding: 120px 0 0;
  box-sizing: border-box;
  min-height: calc(100vh - 120px);
  color: #222;
  background: #fff url('./bgTriangleBottom.png') left bottom/contain no-repeat;
}

.SignUp .wrapper {
  max-width: 500px;
  margin: auto;
  padding: 0 0 20px 0;
}

.SignUp .topQuote {
  margin: 0 10px;
  display: inline-block;
}
.SignUp .portraitCont {
  float: left;
  width: 120px;
}
.SignUp .portrait {
  width: 120px;
}
.SignUp .portraitImg {
  display: block;
  width: 100%;
}
.SignUp .caption {
  color: #666;
  font-size: 12px;
  margin-top: 10px;
}

.SignUp .quote {
  float: left;
  width: calc(100% - 160px);;
  padding: 0 0 0 40px;
  color: #555;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  min-height: 120px;
  display: flex;
  align-items: center;
}
@media (max-width: 500px) {
  .SignUp .quote {
    padding: 0 0 0 15px;
    font-size: 18px;
    line-height: 24px;
  }
}

.SignUp .gap {
  margin-top: 50px;
}

.SignUp .textInput {
  margin: 5px 0 0 0;
  font-size: inherit;
  font-family: inherit;
  padding: 7px;
  border: 1px solid #ccc;
  display: block;
  font-size: 17px;
  width: 100%;
  box-sizing: border-box;
  -webkit-appearance: none;
}


.SignUp .agreeContainer {
  padding-bottom: 40px;
  margin-right: 20px;
}

.SignUp .submit,
.SignUp .agree {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 17px;
  border: 1px solid #007dcc;
  padding: 10px 15px 5px 15px;
  cursor: pointer;
  display: inline-block;
  color: #007dcc;
  background: #fff;
  outline: 0;
  margin: 20px 0 0 0;
  text-decoration: none;
  float: right;
}

.SignUp .later,
.SignUp .cancel {
  font-size: 15px;
  border: 1px solid rgba(0,0,0,0);
  padding: 9px 10px 5px 0;
  cursor: pointer;
  display: inline-block;
  margin-top: 20px;
  color: #007bcf;
  text-decoration: underline;
}

.SignUp .cancel:before {
  content: " ";
  display: inline-block;
  vertical-align: -3px;
  color: #007dcc;
  width: 20px;
  height: 16px;
  margin-right: 5px;
  background: url('./chevron.svg') center/contain no-repeat;
  transform: rotate(180deg);
}

.SignUp .status {
  margin-top: 20px;
}

.SignUp label {
  color: #444;
  font-size: 15px;
  display: block;
  padding-right: 5px;
  margin: 20px 0 5px;
}

.SignUp .longText {
  margin: 30px 10px 0;
  font-size: 17px;
  line-height: 23px;
  color: #333;
}

.SignUp .longText a {
  color: #007dcc;
}

.SignUp .instruction {
}

.SignUp .form {
  padding: 20px 10px;
}

.SignUp .title {
  color: #555;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
}

.SignUp .errorText {
  color: #c70300;
}

.SignUp .errorBottom {
  margin: 5px 0 10px;
  color: #c70300;
}

.SignUp .errorInput {
  border-color: red;
}

.SignUp .tempHide {
  display: none;
}
