.Checkup {
  text-align: center;
  padding: 0;
  position: relative;
}

.Checkup:after {
  clear: both;
  content: " ";
  height: 0;
  display: block;
}

.Checkup .instruction {
  margin: 30px 20px 10px;
  color: #555;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
}

.Checkup .instructionText {
  font-size: 18px;
  color: #444;
  margin: 8px 20px 8px;
}

.Checkup .instructionText2 {
  font-size: 18px;
  color: #444;
  margin: 20px 20px 8px;
}

.Checkup .question {
  font-size: 22px;
  color: #333;
  font-weight: 600;
  margin: 30px 20px 20px;
}

.Checkup .answer {
  width: 160px;
  margin: 0 15px;
  display: inline-block;
  cursor: pointer;
  background: #fff;
  overflow: hidden;
  padding-bottom: 7px;
  border-radius: 10px 10px 0px 0px;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  border-bottom: none;
  transition: border 200ms, color 50ms;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media (max-width: 450px) {
  .Checkup .answer {
      width: 120px;
      margin: 0 10px;
  }
}

.Checkup .answerImg {
  width: 100%;
  height: 110px;
  margin-bottom: 10px;
}
.Checkup .goodSleep {
  background: url('./goodSleep2.jpg') center center/cover;
}
.Checkup .badSleep {
  background: url('./badSleep.jpg') center top/cover;
}

.Checkup .goodMood {
  background: url('./goodMood.jpg') center center/cover;
}
.Checkup .badMood {
  background: url('./badMood.jpg') center center/170%;
}

.Checkup .goodActivity {
  background: url('./goodActivity.jpg') center top/cover;
}
.Checkup .badActivity {
  background: url('./badActivity.jpg') center top/cover;
}

.Checkup .activity {
  border: 1px solid #82c4ed;
  padding: 8px;
  margin: 3px 2px;
  font-size: 16px;
  display: inline-block;
  cursor: pointer;
  color: #007dcc;
  background: #fff;
}
.Checkup .activity.active {
  border: 1px solid #f4c900;
  background: #f4c900;
  color: #fff;
}

.Checkup .section {
  transition: transform 500ms, opacity 0ms;
  transform: translateX(-100%);
  width: 100%;
  padding-bottom: 30px;
  position: absolute;
  top: 0;
  overflow: hidden;
  left: 0;
  opacity: 0;
  pointer-events: none;
}

.Checkup .section.active {
  transform: translateX(0);
  display: block;
  position: static;
  opacity: 1;
  transition: transform 0ms, opacity 400ms;
  pointer-events: all;
}

.Checkup .doneCont {
  width: 100%;
  max-width: 400px;
  margin: auto;
  text-align: left;
  color: #444;
  padding: 30px 10px 20px;
  box-sizing: border-box;
  font-size: 18px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: none;
  text-align: center;
}

.Checkup .doneCont.active {
  display: block;
}

.Checkup .restartCont {
  display: inline-block;
  font-size: 18px;
  line-height: 16px;
  border-radius: 17px;
  border: 1px solid #999;
  padding: 7px 10px 5px 10px;
  cursor: pointer;
  transition: border 200ms, color 50ms;
  margin-right: 10px;
  background: #fff;
  color: #666;
}
.Checkup .restart {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: -5px;
  margin-right: 5px;
  fill: #666;
}

.Checkup .goodAnswer {
  background: #f4c900;
}

.Checkup .badCont {
  transition: transform 200ms;
  background: #6d6f71;
}
.Checkup .badCont.active {
  transform: translateX(-190px);
  box-shadow: none;
}
@media (max-width: 450px) {
  .Checkup .badCont.active {
    transform: translateX(-140px);
  }
}
.Checkup .badCont.inactive {
  opacity: 0;
  pointer-events: none;
}

.Checkup .subCont {
  vertical-align: top;
  pointer-events: none;
  opacity: 0;
  overflow: hidden;
  transition: opacity 200ms 200ms;
  text-align: left;
  overflow: auto;
  float: right;
  display: inline-block;
  width: 0;
  height: 130px;
}
.Checkup .subCont:after {
  content: " ";
  height: 20px;
  display: block;
}
.Checkup .subCont.active {
  pointer-events: auto;
  opacity: 1;
  width: 50%;
  height: auto;
  min-height: 140px;
}

.Checkup .subCont .next {
  font-size: 18px;
  line-height: 16px;
  font-weight: 600;
  color: #007dcc;
  border-radius: 17px;
  border: 1px solid #007dcc;
  padding: 7px 5px 5px 15px;
  cursor: pointer;
  transition: border 200ms, color 50ms;
  display: block;
  width: 56px;
  margin: 10px 0 0 5px;
  bottom: 0px;
  right: 10px;
  background: #fff;
}
.Checkup .subCont .next.active {
  border: 1px solid #007dcc;
  color: #007dcc;
}
.Checkup .subCont .next .chevron {
  fill: #007dcc;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -5px;
}
.Checkup .subCont .next.active .chevron {
  fill: #007dcc;
}

.Checkup .subAnswer {
  border: 1px solid #82c4ed;
  padding: 8px;
  margin: 3px 2px;
  font-size: 16px;
  display: inline-block;
  cursor: pointer;
  color: #007dcc;
  background: #fff;
}
.Checkup .subAnswer.active {
  background: #6d6f71;
  border: 1px solid #6d6f71;
  color: #fff;
}


.Checkup .flag {
  width: 16px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
  cursor: pointer;
}

.Checkup .submit {
  font-size: 18px;
  line-height: 18px;
  border-radius: 17px;
  border: 1px solid #007bcf;
  padding: 6px 15px;
  cursor: pointer;
  -webkit-transition: border 200ms, color 50ms;
  transition: border 200ms, color 50ms;
  display: inline-block;
  margin: 10px 0 0 15px;
  color: #007bcf;
  font-weight: 600;
  background: #fff;
}

.Checkup .addIcon {
  fill: #007dcc;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -5px;
  margin-left: 5px;
}

.Checkup .doneTitle {
  padding-bottom: 10px;
  font-size: 22px;
  font-weight: 600;
}

.Checkup .doneTitleResponse {
  padding-bottom: 10px;
}


.Checkup .doneLeft {
  text-align: left;
}

.Checkup .doneSection {
  margin: 10px;
}

.Checkup .doneAnswer {
  padding-left: 5px;
  display: inline;
  font-weight: 600;
}

.Checkup .doneChoices {
  font-weight: 400;
  padding-left: 5px;
  font-size: 18px;
  display: inline;
}

.Checkup .done {
  width: 25px;
  height: 25px;
  background: url('./tick.svg') center/contain no-repeat;
  display: inline-block;
  vertical-align: middle;
  margin: -5px 0 0 0;
}

.Checkup .square {
  width: 20px;
  height: 7px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-top: -4px;
}
.Checkup .redSquare {
  background: rgb(207, 42, 43);
}
.Checkup .graySquare {
  background: #6d6f71;
}
.Checkup .yellowSquare {
  background: #f4c900;
}

.Checkup .answers {
  position: absolute;
  width: 100%;
  z-index: -1;
}
