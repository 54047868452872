.Home {
  background: #fdf5f4;
  min-height: calc(100vh - 120px);
  position: relative;
  padding-top: 100px;
  box-sizing: border-box;
  overflow: hidden;
}

.Home .head .title {
  font-weight: 600;
}

.Home .prescriptionCont {
  width: 100%;
  position: relative;
  background: #fff;
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
  min-height: 80px;
}

.Home .prescription {
  font-size: 18px;
  font-weight: 600;
  padding: 0px 40px 0px 30px;
  margin: 10px 0;
  position: relative;
  color: #222;
}

.Home .prescriptionCont .today {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 30px;
  background: #1d4381;
}
.Home .prescriptionCont .todayText {
  transform: translateY(-50%) translateX(-32%);
  position: absolute;
  top: 50%;
  text-align: center;
}
.Home .prescriptionCont .rotated {
  transform: rotate(-90deg);
  white-space: nowrap;
  padding-top: 8px;
}

.Home .infoIcon {
  width: 25px;
  height: 25px;
  fill: #007dcc;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.Home .info {
  height: calc(100vh - 110px);
  width: calc(100% - 0px);
  position: absolute;
  top: 110px;
  left: 0px;
  transform: translateX(100%);
  opacity: 0;
  transition: transform 400ms, opacity 400ms;
  background: #fff;
}
.Home .info.active {
  transform: translateX(0%);
  opacity: 1;
  box-shadow: 0 0 5px rgba(0,0,0,0.25);
}

.Home .closeInfo {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px 0 0 18px;
  fill: #444;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media (min-width: 650px) {
  .Home .closeInfo {
    padding: 20px 0 0 40px;
  }
}

.Home .infoContent {
  overflow: auto;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  padding: 30px 20px;
  box-sizing: border-box;
  color: #444;
  max-width: 600px;
  margin: auto;
  font-size: 18px;
  line-height: 23px;
}

.Home .infoContent ul li {
  line-height: 1.5em;
}

.Home .activities {
  padding: 0 30px 0px;
}
.Home .activities .activity {
  display: inline-block;
  font-size: 16px;
  margin: 0px 15px 5px 0 ;
  color: #444;
}
.Home .activities .activity svg {
  fill: #f8bb00;
  width: 22px;
  height: 22px;
  vertical-align: bottom;
}

.Home .portraitCont {
  float: left;
  width: 120px;
}
.Home .portrait {
  width: 120px;
}
.Home .portraitImg {
  display: block;
  width: 100%;
}
.Home .caption {
  color: #666;
  font-size: 13px;
  margin-top: 10px;
}

.Home .noSchedule {
  background: #fff;
  padding: 20px 30px 40px;
}
.Home .quote {
  float: left;
  width: calc(100% - 160px);;
  padding: 0 0 0 40px;
  color: #555;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  min-height: 120px;
  display: flex;
  align-items: center;
}
@media (max-width: 450px) {
  .Home .noSchedule {
    background: #fff;
    padding: 20px 10px 30px;
  }
  .Home .portraitCont {
    margin-left: 0;
    width: 40%;
  }
  .Home .portrait {
    width: 80px;
    height: 80px;
  }
  .Home .quote {
    padding: 0 0 0 15px;
    font-size: 18px;
    line-height: 24px;
  }
}

.Home .steps {
  clear: left;
  padding-top: 10px;
  font-size: 18px;
  line-height: 23px;
  color: #333;
  min-height: 50vh;
}
.Home .stepRow {
  display: block;
  width: 100%;
  padding: 20px 40px; 
  box-sizing: border-box;
}
.Home .stepRow:nth-of-type(odd),
.Home .nextSteps .stepRow:nth-of-type(even) {
  background: #fffcf6;
}
.Home .stepRow:nth-of-type(even),
.Home .nextSteps .stepRow:nth-of-type(odd) {
  background: #fdf5f4;
}


.Home .stepLeft {
  display: inline-block;
  width: 60%;
  color: #555;
  font-size: 22px;
  margin-bottom: 5px;
  box-sizing: border-box;
}
.Home .stepRight {
  display: inline-block;
  width: 40%;
  text-align: right;
  padding-right: 10px;
  box-sizing: border-box;
}
.Home .stepRight img {
  width: 24px;
  vertical-align: bottom;
}

.Home .planLink {
  color: #007dcc;
  font-weight: 600;
  border: 1px solid;
  display: inline-block;
  padding: 10px 40px;
  margin-bottom: 10px;
  border-radius: 20px;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
  cursor: pointer;
}

.Home .planLinkSmall {
  padding: 5px 10px;
  margin-right: 10px;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 0;
}

.Home .tickComplete {
  display: inline-block;
}

.Home .redcapLink {
  color: #007dcc;
  font-weight: 600;
  border: 1px solid;
  display: inline-block;
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 20px;
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
  cursor: pointer;
}

.Home .rcLink {
  color: #007dcc;
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
  font-weight: 600;
}

.Home .planConfirm {
  color: #007dcc;
  font-weight: 600;
  border: 1px solid #007dcc;
  display: inline-block;
  padding: 10px 20px;
  margin: 0 10px 0 10px;
  border-radius: 0;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
  cursor: pointer;
}

.Home .stepConfirm {
  color: #007dcc;
  text-align: right;
  padding-top: 30px;
}
