.Plan {
  background : #fdf5f4;
  min-height: calc(100vh - 120px);
  box-sizing: border-box;
}

.Plan .topGap {
  margin: 40px 0 0;
}

.Plan .wrapper {
  clear: both;
  padding: 10px 30px 90px;
}

.Plan label {
  color: #333;
  font-size: 16px;
  padding: 15px 0 5px;
  display: block;
}

.Plan input[type='text'],
.Plan input[type='tel'],
.Plan input[type='email'],
.Plan input[type='date'],
.Plan input[type='password'] {
  color: #333;
  font-size: 18px;
  background: #fff;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  max-width: 250px;
  border-radius: 2px;
  border: 1px solid #bbb;
  box-shadow: none;
  font-family: inherit;
}
.Plan input[type='text'],
.Plan input[type='email'],
.Plan input[type='tel'] {
  -webkit-appearance: none;
}
.Plan input[type='email'] {
  max-width: 400px;
}

.Plan .addItem {
  font-weight: 600;
  color: #007dcc;
  font-size: 16px;
  display: block;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
}

.Plan .spaced {
  margin: 0;
}
.Plan .spaced:after {
  content: " ";
  height: 0;
  display: block;
  clear: both;
}

.Plan .RemoveIcon {
  display: inline-block;
  margin-left: 10px;
  line-height: 15px;
}

.Plan .RemoveIcon .minusBox {
  display: inline-block;
}
.Plan .RemoveIcon .minus {
  cursor: pointer;
  fill: #aaa;
  width: 15px;
  height: 15px;
}
.Plan .RemoveIcon .remove {
  cursor: pointer;
  display: inline-block;
  color: #007dcc;
  font-size: 13px;
  margin-left: 10px;
  border: 1px solid #007dcc;
  border-radius: 15px;
  padding: 4px 5px;
  background: #fff;
}
.Plan .RemoveIcon .cancel {
  cursor: pointer;
  color: #999;
  font-size: 13px;
  display: inline-block;
  border: 1px solid #999;
  border-radius: 15px;
  padding: 4px 5px;
  background: #fff;
}

.Plan .flag {
  width: 27px;
  height: 25px;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #94c4e3;
}

.Plan .saveBox {
  width: 100%;
  max-width: 400px;
}

.Plan .saveButton {
  font-size: 18px;
  font-weight: 600;
  color: #007dcc;
  border-radius: 17px;
  border: 1px solid #007dcc;
  padding: 6px 10px 6px 10px;
  cursor: pointer;
  transition: border 200ms, color 50ms;
  display: inline-block;
  float: right;
  margin-top: 10px;
  bottom: 0px;
  right: 10px;
  background: #fff;
}
.Plan .addButton {
  float: none;
  margin-left: 10px;
}

.Plan .saveButton .saveIcon {
  fill: #007dcc;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -5px;
  margin-left: 6px;
}


.Plan .saveButton .doneIcon {
  fill: #444;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -5px;
  margin-left: 6px;
}

.Plan .halfCol {
  width: 50%;
  float: left;
}

.Plan .prescription {
  margin-top: 20px;
  background: rgb(255,253,241);
  padding: 10px;
  border: 1px solid rgba(0,0,0,0.05);
}

.Plan .prescription:after {
  content: " ";
  height: 0;
  display: block;
  clear: both;
}

.Plan input.inlineInput {
  width: calc(100% - 90px);
  max-width: 200px;
  display: inline-block;
  margin: 0 0 10px;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.Plan .saveButton .spinnerIcon {
  fill: #444;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -5px;
  margin-left: 6px;
  animation: spinner .9s linear infinite;
}

.Plan .scheduleOptions {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #bbb;
  margin: 5px 0 30px;
}
.Plan .scheduleOption {
  padding: 10px 10px 5px 10px;
  cursor: pointer;
  background: #fff;
  color: #333;
  font-size: 18px;
  border-top: 1px solid #bbb;
}
.Plan .scheduleOption:first-of-type {
  border-top: none;
}
.Plan .scheduleOption.active {
  background: #007dcc;
  color: #fff;
}

.Plan .scheduleDesc {
  color: #444;
}
.Plan .scheduleDesc ul {
  margin: 8px 0;
}
.Plan .scheduleDesc ul li {
  padding: 5px 0;
  line-height: 1.5em;
}

.Plan .group {
  margin: 10px auto 20px;
  max-width: 600px;
}

.Plan .group:after {
  clear: both;
  content: " ";
  height: 0;
  display: block;
}

.Plan .firstGroup {
  padding-top: 60px;
}

.Plan .symptom {
  margin-top: 5px;
  font-size: 18px;
}
.Plan .symptomText {
  font-size: 18px;
  color: #333;
  display: inline-block;
}

.Plan .activity {
  font-size: 18px;
  color: #333;
  padding: 5px 0;
}

.Plan .title {
  font-size: 20px;
  color: #333;
  font-weight: 600;
}

.Plan .titleSpace {
  padding: 10px 0 5px;
}

.Plan .titleTracker {
  font-size: 20px;
  color: #333;
  font-weight: 600;
  margin: 15px 0 5px;
}

.Plan .instruction {
  font-size: 18px;
  line-height: 20px;
  padding: 5px 0 5px;
  color: #444;
}

.Plan .instructionHelper {
  font-size: 18px;
  line-height: 20px;
  padding: 10px 0 0;
  color: #444;
}

.Plan .instruction li {
  margin-bottom: 5px;
}

.Plan .instruction input[type='checkbox'] {
  margin: 0 0 0 20px;
  padding: 0;
  cursor: pointer;
  width: 18px;
  height: 18px;
}


.Plan .dosetitle {
  font-size: 20px;
  color: #333;
  font-weight: 600;
}

.Plan .persondetail {
  font-size: 18px;
  color: #444;
  padding-bottom: 5px;
}

.Plan .editIcon {
  display: inline-block;
  vertical-align: -2px;
  margin-left: 10px;
  height: 20px;
  width: 20px;
  background: url('./edit.svg') center/contain no-repeat;
  cursor: pointer;
}

.Plan .topQuote {
  max-width: 600px;
  margin: 0 auto;
  padding: 80px 30px 0;
}
.Plan .portraitCont {
  float: left;
  width: 120px;
}
.Plan .portrait {
  width: 120px;
}
.Plan .portraitImg {
  display: block;
  width: 100%;
}
.Plan .caption {
  color: #666;
  font-size: 13px;
  margin-top: 10px;
}

.Plan .quote {
  float: left;
  width: calc(100% - 160px);;
  padding: 0 0 0 40px;
  color: #555;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  min-height: 120px;
  display: flex;
  align-items: center;
}
@media (max-width: 500px) {
  .Plan .quote {
    padding: 0 0 0 15px;
    font-size: 18px;
    line-height: 24px;
  }
}

.Plan .longText {
  margin: 60px 20px 0;
  font-size: 18px;
  line-height: 23px;
  color: #333;
  margin: auto;
  max-width: 600px;
}

.Plan .longText strong {
  font-weight: 600;
}

.Plan .questionTopText {
  padding: 10px 0;
  font-weight: 600;
  font-size: 18px;
}

.Plan .firstTime .questionBox {
  padding-bottom: 20px;
}

.Plan .firstTime .questionText {
  padding-bottom: 10px;
}

.Plan .firstTime .questionButtons {
  margin-left: 10px;
}

.Plan .firstTime .questionTimes {
  margin: 0 0 0 10px;
  font-size: 16px;
}

.Plan .firstTime .optionCont {
  margin: 5px 0 10px;
  display: inline-block;
}
.Plan .firstTime .Option {
  padding: 7px;
  margin: 0 8px 0 0;
  border: 1px solid #82c4ed;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 18px;
  color: #007dcc;
  text-align: center;
  min-width: 60px;
  background: #fff;
}

.Plan .firstTime .Option.active {
  background: #007dcc;
  color: #fff;
}

.Plan .recommendTop {
  padding: 10px 0 10px;
  font-size: 18px;
}

.Plan .recommendSchedule {
  margin: 10px 0 0;
}

.Plan .recommendSchedule h3 {
  font-weight: 600;
  font-size: 20px;
  margin: 10px 0 0;
  display: inline-block;
}
.Plan .recommendSchedule li {
  margin: 0;
  font-size: 18px;
}

.Plan .buttonsExtra {
  margin: 50px 50px 70px;
}

.Plan .buttons {
  margin: 20px 0 70px;
}

.Plan .back {
  cursor: pointer;
  color: #007dcc;
  border: 1px solid rgba(0,0,0,0);
  display: inline-block;
  padding: 12px 10px 5px 5px;
  border-radius: 15px;
  float: left;
  margin: 8px 0px 20px;
  font-size: 18px;
  text-decoration: underline;
}
.Plan .back:before {
  content: " ";
  display: inline-block;
  vertical-align: -3px;
  color: #007dcc;
  width: 20px;
  height: 16px;
  background: url('./chevron.svg') center/contain no-repeat;
  transform: rotate(180deg);
}

.Plan .next {
  cursor: pointer;
  color: #007dcc;
  font-weight: 600;
  border: 1px solid;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  float: right;
  margin: 10px 20px 0 0;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
  background: #fff;
}
.Plan .next:after {
  content: " ";
  display: inline-block;
  vertical-align: -3px;
  width: 20px;
  height: 16px;
  margin-left: 0;
  background: url('./chevron.svg') center/contain no-repeat;
}

.Plan .next.inactive {
  color: #aaa;
  cursor: default;
}
.Plan .next.inactive:after {
  background: url('./chevronGray.svg') center/contain no-repeat;
  opacity: 0.5;
}


.Plan .print {
  cursor: pointer;
  color: #007dcc;
  font-weight: 600;
  border: 1px solid;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  margin: 0 0 0 20px;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
  background: #fff;
}


.Plan .scheduleMedCustomRow {
  display: block;
  margin-bottom: 5px;
}
.Plan .scheduleMedCustomRow .textInputDate {
  width: 130px;
}
.Plan .scheduleMedCustomRow .textInputDose {
  width: 50px;
  margin-left: 10px;
}
.Plan .scheduleMedCustomRow .textInputNote {
  width: 350px;
  max-width: 350px;
  margin-left: 10px;
}
.Plan .scheduleMedCustomRow label {
  display: inline-block;
  margin-left: 10px;
}
.Plan .saveMed {
  cursor: pointer;
  color: #007dcc;
  font-weight: 600;
  border: 1px solid;
  padding: 5px 15px;
  border-radius: 20px;
  margin: 10px 0 0 0;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
  background: #fff;
  width: fit-content;
}

@media print {
  .nav,
  .acting,
  .Plan .editIcon,
  .Plan .dosetitle,
  .Plan .scheduleOptions,
  .Plan .print,
  .Plan .buttons,
  .Plan .buttonsExtra,
  .Plan .scheduleMedCustom,
  .Plan .groupDoc,
  .Plan .groupSupport,
  .Plan .groupActivities,
  .Plan .groupTrack {
    display: none;
  }
  .Plan {
    min-height: auto;
    background: #fff;
  }
  body {
    background: #fff !important;
  }
}

