.Survey {
  padding: 120px 0 30px;
  min-height: calc(100vh - 120px);
  box-sizing: border-box;
  background: #fff url('./bgTriangleBottom.png') left bottom/contain no-repeat;
}

.Survey .topQuote {
  margin: 0 20px;
  display: inline-block;
}
.Survey .portraitCont {
  float: left;
  width: 120px;
}
.Survey .portrait {
  width: 120px;
}
.Survey .portraitImg {
  display: block;
  width: 100%;
}
.Survey .caption {
  color: #666;
  font-size: 13px;
  margin-top: 10px;
}

.Survey .quote {
  float: left;
  width: calc(100% - 160px);;
  padding: 0 0 0 40px;
  color: #555;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  min-height: 120px;
  display: flex;
  align-items: center;
}
@media (max-width: 500px) {
  .Survey .quote {
    padding: 0 0 0 15px;
    font-size: 18px;
    line-height: 24px;
  }
}

.Survey .longText {
  margin: 30px 20px 0;
  font-size: 18px;
  line-height: 23px;
  color: #333;
}

.Survey .longText a {
  color: #007dcc;
}

.Survey .longText ol {
  padding-inline-start: 20px;
}

.Survey .longText li {
  margin-top: 15px;
}

.Survey .optionYesNo {
  text-align: center;
  padding: 10px 0 20px;
}
.Survey .optionYesNo .optionCont {
  margin: 5px 0 10px;
  display: inline-block;
}
.Survey .optionGroup {
  text-align: left;
  padding: 10px 0 20px;
}
.Survey .optionGroup .optionCont {
  margin: 5px 20px 15px;
  display: block;
}
.Survey .Option {
  padding: 7px 10px;
  margin: 0;
  border: 1px solid #82c4ed;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 18px;
  color: #007dcc;
  text-align: center;
  min-width: 70px;
  background: #fff;
  display: inline-block;
}
.Survey .optionYesNo .Option {
  padding: 10px 20px;
  margin: 0 8px 0 0;
}

.Survey .Option.active {
  background: #007dcc;
  color: #fff;
}

.Survey .back {
  cursor: pointer;
  color: #007dcc;
  display: inline-block;
  float: left;
  margin: 22px 0px 20px;
  font-size: 18px;
  text-decoration: underline;
}
.Survey .back:before {
  content: " ";
  display: inline-block;
  vertical-align: -3px;
  color: #007dcc;
  width: 20px;
  height: 16px;
  margin-right: 2px;
  background: url('./chevron.svg') center/contain no-repeat;
  transform: rotate(180deg);
}


.Survey .next {
  cursor: pointer;
  color: #007dcc;
  font-weight: 600;
  border: 1px solid;
  display: inline-block;
  padding: 10px 10px 10px 15px;
  border-radius: 20px;
  float: right;
  margin: 10px 20px;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
}
.Survey .next:after {
  content: " ";
  display: inline-block;
  vertical-align: -3px;
  width: 20px;
  height: 16px;
  margin-left: 0;
  background: url('./chevron.svg') center/contain no-repeat;
}

.Survey .next.inactive {
  color: #aaa;
  cursor: default;
}
.Survey .next.inactive:after {
  background: url('./chevronGray.svg') center/contain no-repeat;
  opacity: 0.5;
}

.Survey .page {
  padding-bottom: 110px;
  max-width: 600px;
  margin: auto;
}
.Survey .page:after {
  clear: both;
  display: block;
  content: " ";
  height: 0;
}

.Survey .pageNumbers {
  text-align: center;
  margin: 10px 0 0;
}
.Survey .pageNumber {
  display: inline-block;
  width: 20px;
  height: 20px;
  color: #007dcc;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 15px;
}
.Survey .pageNumber.active {
  color: #222;
  text-decoration: none;
}

.Survey .noMargin {
  margin-top: 0;
}

.Survey .title {
  margin: 10px 10px 20px 10px;
  color: #555;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
}

.Survey .flag {
  height: 15px;
  width: 15px;
  display: inline-block;
  vertical-align: -3px;
  margin-right: 3px;
}

.Survey .optionFlag {
  margin-top: -6px;
}

.Survey .shortCircuit {
  margin-top: 30px;
}
.Survey .shortCircuit .youAnswered {
  display: inline-block;
}
.Survey .shortCircuit .youCont {
  margin: 0 0 0 60px;
  display: inline-block;
}
.Survey .shortCircuit .prefer {
  margin: 10px 0 0;
  padding: 10px 0 0;
  display: inline-block;
}
.Survey .shortCircuit .youNext {
  margin: 10px 0 0 15px;
  float: none;
}

.Survey .feedback {
  margin: 30px 0;
}

.Survey .closeSurvey {
  cursor: pointer;
  color: #007dcc;
  font-weight: 600;
  border: 1px solid;
  padding: 10px 15px;
  border-radius: 20px;
  float: right;
  margin: 10px 20px 0 0;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
}

.Survey .answerText {
  font-weight: 600;
  margin-top: 5px;
  display: inline-block;
}
.Survey .answerEdit {
  cursor: pointer;
  color: #007dcc;
  display: inline-block;
  font-size: 17px;
  padding-left: 10px;
  text-decoration: underline;
}

.Survey .printIcon {
  height: 30px;
  width: 30px;
  margin: -8px 0 0 0;
  float: left;
  background: url('./print.svg') center/contain no-repeat;
  cursor: pointer;
}
.Survey .printText {
  color: #007dcc;
  display: inline-block;
  text-decoration: underline;
  padding-left: 5px;
  cursor: pointer;
}

.Survey .headingText {
  font-weight: 600;
  font-size: 22px;
  padding: 10px 0 0;
}

