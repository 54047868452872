.PublicNav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #eee;
  z-index: 1;
}

.PublicNav .inner {
  position: relative;
  max-width: 768px;
  margin: auto;
  box-sizing: border-box;
  background: #fff;
  border-bottom: 1px solid #a7dde3;
}

.PublicNav .inner:after {
  clear: both;
  content: " ";
  height: 0;
  display: block;
}

.PublicNav .nav {
  float: left;
  width: 80%;
  text-align: right;
}

.PublicNav .navItem {
  display: inline-block;
  margin: 5% 12% 0 0;
  padding: 10px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  transition: color 500ms, transform 100ms;
  transform-origin: center center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #149dac;
}
@media (max-width: 700px) {
  .PublicNav .navItem {
    margin: 4% 8% 0 0;
  }
}
@media (max-width: 550px) {
  .PublicNav .navItem {
    margin: 4% 4% 0 0;
    font-size: 15px;
  }
}
@media (max-width: 410px) {
  .PublicNav .navItem {
    margin: 3% 2% 0 0;
    padding: 10px 5px 5px;
    font-size: 15px;
  }
}

.PublicNav .navLink {
  cursor: pointer;
}

.PublicNav .navItem.active {
  transform: scale(1.1);
  color: #444;
}

.PublicNav .logo {
  float: left;
  width: 20%;
}

.PublicNav .logoImg {
  display: block;
  width: 90%;
}

