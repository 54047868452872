.About {
  padding: 50px 0 50px;
  box-sizing: border-box;
  background: #fff;
  min-height: calc(100vh - 120px);
  color: #222;
  background: #fff url('./bgTriangleBottom.png') left bottom/contain no-repeat;
}

.About .topQuote {
  margin: 50px 20px 10px;
  display: inline-block;
}
.About .portraitCont {
  float: left;
  width: 120px;
}
.About .portrait {
  width: 120px;
}
.About .portraitImg {
  display: block;
  width: 100%;
}
.About .caption {
  color: #666;
  font-size: 13px;
  margin-top: 10px;
}

.About .quote {
  float: left;
  width: calc(100% - 160px);;
  padding: 0 0 0 40px;
  color: #555;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  min-height: 120px;
  display: flex;
  align-items: center;
}
@media (max-width: 500px) {
  .About .quote {
    padding: 0 0 0 15px;
    font-size: 18px;
    line-height: 24px;
  }
}

.About .wrapper {
  max-width: 600px;
  margin: auto;
  padding: 20px 0;
}

.About .longText {
  margin: 0 20px 0;
  font-size: 17px;
  line-height: 23px;
  color: #333;
}

.About .longText a {
  color: #007dcc;
}

.About .longText ul,
.About .longText ol {
  padding-inline-start: 24px;
}

.About .longText li {
  margin-top: 10px;
}

.About .longText h1 {
  margin: 40px 0 30px;
  color: #555;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
}

.About .longText h2 {
  margin-top: 30px;
  color: #444;
  font-size: 18px;
  font-weight: 600;
}

.About .longText h3 {
  margin-top: 16px;
  color: #444;
  font-size: 16px;
}
