.Nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #eee;
}
.Nav .fixed {
  position: relative;
  max-width: 768px;
  margin: auto;
  box-sizing: border-box;
  background: #fff;
  border-bottom: 1px solid #a7dde3;
}
.Nav .fixed:after {
  clear: both;
  content: " ";
  height: 0;
  display: block;
}

.Nav .nav {
  float: left;
  width: 80%;
  text-align: center;
}

.Nav .navItem {
  display: inline-block;
  margin: 4% 7% 0;
  text-align: center;
  height: 53px;
  padding: 5px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 16px;
  transition: color 500ms, transform 100ms;
  transform-origin: center center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #149dac;
}
@media (max-width: 625px) {
  .Nav .navItem {
    margin: 3% 5% 0;
  }
}
@media (max-width: 460px) {
  .Nav .navItem {
    margin: 1% 2% 0;
    font-size: 15px;
  }
}

.Nav .navItem.active {
  transform: scale(1.1);
  color: #444;
}

.Nav .navItem svg {
  display: block;
  margin: auto;
  height: 20px;
  transition: fill 500ms;
  fill: #149dac;
  padding-bottom: 5px;
}
.Nav .navItem.active svg {
  fill: #444;
}

.Nav .navIcon {
  width: 15px;
  height: 15px;
  margin: auto;
  background: #fff center/contain;
}

.Nav .logout {
  cursor: pointer;
}
.Nav .logout svg {
  fill: none;
}

.Nav .logo {
  float: left;
  width: 20%;
}

.Nav .logoImg {
  display: block;
  width: 90%;
}

.Nav .acting {
  position: absolute;
  top: 5px;
  right: 20px;
  color: #d01515;
  font-size: 15px;
}
