.Login {
  padding: 80px 0 0;
  box-sizing: border-box;
  background: #fff;
  min-height: calc(100vh - 120px);
  color: #222;
  background: #fff url('./bgTriangleBottom.png') left bottom/contain no-repeat;
}

.Login .wrapper {
  max-width: 400px;
  margin: auto;
  padding: 20px 10px;
}

.Login .quote {
  display: inline-block;
  vertical-align: middle;
  width: calc(60% - 20px);
  box-sizing: border-box;
  padding: 0 10px;
  font-size: 18px;
  font-weight: 300;
  color: #014085;
}

.Login label {
  color: #444;
  font-size: 18px;
  display: block;
  padding-right: 5px;
  margin: 20px 0 10px;
}

.Login .textInput {
  font-size: 18px;
  font-family: inherit;
  padding: 7px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  -webkit-appearance: none;
}

.Login .submit {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  border-radius: 17px;
  border: 1px solid #007bcf;
  padding: 11px 20px;
  cursor: pointer;
  -webkit-transition: border 200ms, color 50ms;
  transition: border 200ms, color 50ms;
  display: inline-block;
  margin-top: 25px;
  bottom: 0px;
  right: 10px;
  color: #007bcf;
  background: #fff;
  float: right;
}

.Login .cancelLink {
  font-size: 18px;
  border: 1px solid rgba(0,0,0,0);
  padding: 17px 10px 5px 0;
  cursor: pointer;
  display: inline-block;
  margin-top: 20px;
  color: #007bcf;
  text-decoration: underline;
}

.Login .cancelLink:before {
  content: " ";
  display: inline-block;
  vertical-align: -3px;
  color: #007dcc;
  width: 20px;
  height: 16px;
  margin-right: 5px;
  background: url('./chevron.svg') center/contain no-repeat;
  transform: rotate(180deg);
}

.Login .status {
  margin-top: 20px;
}

.Login .errorText {
  color: red;
}

.Login .errorBottom {
  margin: 5px 0 10px;
}

.Login .errorInput {
  border-color: red;
}

.Login .topMargin {
  margin-top: 50px;
}

.Login .password {
  display: inline-block;
}
.Login .forgot {
  display: inline-block;
  padding-left: 20px;
  font-size: 16px;
}
.Login .forgot a {
  color: #007bcf;
}
