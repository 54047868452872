.Contact {
  background: #fdf5f4;
  padding: 100px 0 30px;
  min-height: calc(100vh - 120px);
  overflow: auto;
  box-sizing: border-box;
}

.Contact .wrapper {
  max-width: 600px;
  padding: 20px 0;
  margin: auto;
  box-sizing: border-box;
}

.Contact .card {
  margin: 0;
  padding: 0px 0 30px;
  color: #444;
  line-height: 1.4;
}
.Contact .card p {
  font-size: 18px;
  margin: 5px 0;
}

.Contact .instruction {
  font-size: 18px;
  line-height: 20px;
  padding: 0 0 5px;
  color: #666;
}

.Contact .name {
  font-size: 20px;
  margin-top: 10px;
  color: #333;
  font-weight: 600;
}
.Contact .email {
  color: #007dcc;
  display: block;
  margin-top: 10px;
  text-decoration: underline;
  font-size: 18px;
}
.Contact .phone {
  color: #007dcc;
  display: block;
  margin-top: 10px;
  text-decoration: underline;
  font-size: 18px;
  padding-bottom: 5px;
}
.Contact .website {
  color: #007dcc;
  display: block;
  margin-top: 10px;
  text-decoration: underline;
  font-size: 18px;
}
.Contact a {
  color: #007dcc;
}

.Contact .noMargin {
  margin: 0;
}

.Contact .Header {
  margin-bottom: 40px;
}

.Contact .editIcon {
  display: inline-block;
  vertical-align: -2px;
  margin-left: 10px;
  height: 20px;
  width: 20px;
  background: url('./edit.svg') center/contain no-repeat;
  cursor: pointer;
}

.Contact .textInput {
  color: #333;
  font-size: 18px;
  background: #fff;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  max-width: 250px;
  border-radius: 2px;
  border: 1px solid #bbb;
  box-shadow: none;
  font-family: inherit;
}

.Contact input[type='text'],
.Contact input[type='email'],
.Contact input[type='tel'] {
  -webkit-appearance: none;
}
.Contact input[type='email'] {
  max-width: 400px;
}

.Contact label {
  font-size: 18px;
  display: block;
}

.Contact .spaced {
  margin-top: 10px;
}

.Contact .spaced:after {
  clear: both;
  content: " ";
  height: 0;
  display: block;
}

.Contact .saveButton {
  font-size: 18px;
  font-weight: 600;
  color: #007dcc;
  border-radius: 17px;
  border: 1px solid #007dcc;
  padding: 7px 10px;
  cursor: pointer;
  transition: border 200ms, color 50ms;
  display: inline-block;
  float: right;
  margin-top: 10px;
  bottom: 0px;
  right: 10px;
  background: #fff;
}


.Contact .saveButton .saveIcon {
  fill: #007dcc;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -5px;
  margin-left: 6px;
}


.Contact .saveButton .doneIcon {
  fill: #444;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -5px;
  margin-left: 6px;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.Contact .saveButton .spinnerIcon {
  fill: #444;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -5px;
  margin-left: 6px;
  animation: spinner .9s linear infinite;
}

.Contact .back {
  cursor: pointer;
  color: #007dcc;
  border: 1px solid rgba(0,0,0,0);
  display: inline-block;
  padding: 12px 10px 5px 5px;
  border-radius: 15px;
  float: left;
  margin: 8px 0px 20px;
  font-size: 16px;
  text-decoration: underline;
}
.Contact .back:before {
  content: " ";
  display: inline-block;
  vertical-align: -3px;
  color: #007dcc;
  width: 20px;
  height: 16px;
  background: url('./chevron.svg') center/contain no-repeat;
  transform: rotate(180deg);
}

.Contact .saveBox {
  width: 100%;
  max-width: 400px;
}
