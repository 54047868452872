.DataConsent {
  background: #fdf5f4;
  min-height: calc(100vh - 120px);
  position: relative;
  padding-top: 100px;
  box-sizing: border-box;
  overflow: hidden;
}

.DataConsent .wrapper {
  clear: both;
  padding: 10px 40px 90px;
}

.DataConsent .portraitCont {
  float: left;
  width: 120px;
}
.DataConsent .portrait {
  width: 120px;
}
.DataConsent .portraitImg {
  display: block;
  width: 100%;
}
.DataConsent .caption {
  color: #666;
  font-size: 13px;
  margin-top: 10px;
}

.DataConsent .page {
  padding: 20px 30px 40px;
}
.DataConsent .quote {
  float: left;
  width: calc(100% - 160px);;
  padding: 0 0 0 40px;
  color: #555;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  min-height: 120px;
  display: flex;
  align-items: center;
}
@media (max-width: 450px) {
  .DataConsent .page {
    background: #fff;
    padding: 20px 10px 30px;
  }
  .DataConsent .portraitCont {
    margin-left: 0;
    width: 40%;
  }
  .DataConsent .portrait {
    width: 80px;
    height: 80px;
  }
  .DataConsent .quote {
    vertical-align: top; 
    font-size: 18px;
    padding: 10px 0 0 10px;
  }
}

.DataConsent .buttons {
  margin: 20px 0 70px;
}

.DataConsent .back {
  cursor: pointer;
  color: #007dcc;
  border: 1px solid rgba(0,0,0,0);
  display: inline-block;
  padding: 12px 10px 5px 5px;
  border-radius: 15px;
  float: left;
  margin: 8px 0px 20px;
  font-size: 16px;
  text-decoration: underline;
}
.DataConsent .back:before {
  content: " ";
  display: inline-block;
  vertical-align: -3px;
  color: #007dcc;
  width: 20px;
  height: 16px;
  background: url('./chevron.svg') center/contain no-repeat;
  transform: rotate(180deg);
}

.DataConsent .next {
  cursor: pointer;
  color: #007dcc;
  font-weight: 600;
  border: 1px solid;
  display: inline-block;
  padding: 10px 10px;
  border-radius: 20px;
  float: right;
  margin: 10px 20px 0 0;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
  background: #fff;
}
.DataConsent .next:after {
  content: " ";
  display: inline-block;
  vertical-align: -3px;
  width: 20px;
  height: 16px;
  margin-left: 0;
  background: url('./chevron.svg') center/contain no-repeat;
}

.DataConsent .msg {
  margin-top: 25px;
}
.DataConsent .title {
  font-size: 20px;
  color: #333;
  font-weight: 600;
}
.DataConsent .instruction {
  font-size: 18px;
  line-height: 20px;
  padding: 5px 0 5px;
  color: #444;
}

