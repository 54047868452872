.Consent {
  background : #fdf5f4;
  min-height: calc(100vh - 120px);
  box-sizing: border-box;
}

.Consent .topGap {
  margin: 40px 0 0;
}

.Consent .wrapper {
  clear: both;
  padding: 10px 30px 90px;
}




.Consent .flag {
  width: 27px;
  height: 25px;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #94c4e3;
}

.Consent .saveBox {
  width: 100%;
  max-width: 400px;
}

.Consent .saveButton {
  font-size: 18px;
  font-weight: 600;
  color: #007dcc;
  border-radius: 17px;
  border: 1px solid #007dcc;
  padding: 6px 10px;
  cursor: pointer;
  transition: border 200ms, color 50ms;
  display: inline-block;
  float: right;
  margin-top: 10px;
  bottom: 0px;
  right: 10px;
  background: #fff;
}
.Consent .addButton {
  float: none;
  margin-left: 10px;
}

.Consent .saveButton .saveIcon {
  fill: #007dcc;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -5px;
  margin-left: 6px;
}


.Consent .saveButton .doneIcon {
  fill: #444;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -5px;
  margin-left: 6px;
}

.Consent .halfCol {
  width: 50%;
  float: left;
}

.Consent .prescription {
  margin-top: 20px;
  background: rgb(255,253,241);
  padding: 10px;
  border: 1px solid rgba(0,0,0,0.05);
}

.Consent .prescription:after {
  content: " ";
  height: 0;
  display: block;
  clear: both;
}

.Consent input.inlineInput {
  width: calc(100% - 90px);
  max-width: 200px;
  display: inline-block;
  margin: 0 0 10px;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.Consent .saveButton .spinnerIcon {
  fill: #444;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -5px;
  margin-left: 6px;
  animation: spinner .9s linear infinite;
}

.Consent .scheduleOptions {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #bbb;
  margin: 5px 0 30px;
}
.Consent .scheduleOption {
  padding: 10px 10px 5px 10px;
  cursor: pointer;
  background: #fff;
  color: #333;
  font-size: 18px;
  border-top: 1px solid #bbb;
}
.Consent .scheduleOption:first-of-type {
  border-top: none;
}
.Consent .scheduleOption.active {
  background: #007dcc;
  color: #fff;
}

.Consent .scheduleDesc {
  color: #444;
}
.Consent .scheduleDesc ul {
  margin: 8px 0;
}
.Consent .scheduleDesc ul li {
  padding: 5px 0;
}

.Consent .group {
  margin: 10px auto 10px;
  max-width: 600px;
}

.Consent .group:after {
  clear: both;
  content: " ";
  height: 0;
  display: block;
}

.Consent .firstGroup {
  padding-top: 60px;
}

.Consent .symptom {
  margin-top: 5px;
}
.Consent .symptomText {
  font-size: 18px;
  color: #333;
  display: inline-block;
}

.Consent .activity {
  font-size: 18px;
  color: #333;
  padding: 5px 0;
}

.Consent .title {
  font-size: 20px;
  color: #333;
  font-weight: 600;
}

.Consent .pdfdownload {
  font-size: 15px;
  font-weight: 400;
  display: inline-block;
  padding-left: 20px;
}
.Consent .pdfdownload a {
  color: #007dcc;
}

.Consent .titleTracker {
  font-size: 20px;
  color: #333;
  font-weight: 600;
  margin: 15px 0 5px;
}

.Consent .instruction {
  font-size: 18px;
  line-height: 22px;
  padding: 5px 0 5px;
  color: #444;
}
.Consent .instruction p {
  margin: 0 0 10px 0;
}

.Consent .instruction a {
  color: #007dcc;
}

.Consent .instructionHelper {
  font-size: 18px;
  line-height: 20px;
  padding: 10px 0 0;
  color: #444;
}

.Consent .instruction li {
  margin-bottom: 5px;
}

.Consent .instruction input[type='checkbox'] {
  margin: 0 0 0 20px;
  padding: 0;
  cursor: pointer;
  width: 18px;
  height: 18px;
}



.Consent .topQuote {
  max-width: 600px;
  margin: 0 auto;
  padding: 80px 30px 0;
}
.Consent .portraitCont {
  float: left;
  width: 120px;
}
.Consent .portrait {
  width: 120px;
}
.Consent .portraitImg {
  display: block;
  width: 100%;
}
.Consent .caption {
  color: #666;
  font-size: 13px;
  margin-top: 10px;
}

.Consent .quote {
  float: left;
  width: calc(100% - 160px);;
  padding: 0 0 0 40px;
  color: #555;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  min-height: 120px;
  display: flex;
  align-items: center;
}
@media (max-width: 500px) {
  .Consent .quote {
    padding: 0 0 0 15px;
    font-size: 18px;
    line-height: 24px;
  }
}

.Consent .longText {
  margin: 60px 20px 0;
  font-size: 18px;
  line-height: 23px;
  color: #333;
  margin: auto;
  max-width: 600px;
}

.Consent .longText strong {
  font-weight: 600;
}

.Consent .questionTopText {
  padding: 10px 0;
  font-weight: 600;
  font-size: 18px;
}

.Consent .firstTime .questionBox {
  padding-bottom: 20px;
}

.Consent .firstTime .questionText {
  padding-bottom: 10px;
}

.Consent .firstTime .questionButtons {
  margin-left: 10px;
}

.Consent .firstTime .questionTimes {
  margin: 15px 0 0 10px;
}

.Consent .firstTime .optionCont {
  margin: 5px 0 10px;
  display: inline-block;
}
.Consent .firstTime .Option {
  padding: 7px;
  margin: 0 8px 0 0;
  border: 1px solid #82c4ed;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 18px;
  color: #007dcc;
  text-align: center;
  min-width: 60px;
  background: #fff;
}

.Consent .firstTime .Option.active {
  background: #007dcc;
  color: #fff;
}


.Consent .buttons {
  margin: 20px 0 70px;
}

.Consent .back {
  cursor: pointer;
  color: #007dcc;
  border: 1px solid rgba(0,0,0,0);
  display: inline-block;
  padding: 12px 10px 5px 5px;
  border-radius: 15px;
  float: left;
  margin: 8px 0px 20px;
  font-size: 16px;
  text-decoration: underline;
}
.Consent .back:before {
  content: " ";
  display: inline-block;
  vertical-align: -3px;
  color: #007dcc;
  width: 20px;
  height: 16px;
  background: url('./chevron.svg') center/contain no-repeat;
  transform: rotate(180deg);
}

.Consent .next {
  cursor: pointer;
  color: #007dcc;
  font-weight: 600;
  border: 1px solid;
  display: inline-block;
  padding: 10px;
  border-radius: 20px;
  float: right;
  margin: 10px 20px;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
  background: #fff;
}
.Consent .next:after {
  content: " ";
  display: inline-block;
  vertical-align: -3px;
  width: 20px;
  height: 16px;
  margin-left: 0;
  background: url('./chevron.svg') center/contain no-repeat;
}

.Consent .next.inactive {
  color: #aaa;
  cursor: default;
}
.Consent .next.inactive:after {
  background: url('./chevronGray.svg') center/contain no-repeat;
  opacity: 0.5;
}
